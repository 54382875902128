<template>
  <div class="day">


    <div class="subnav">
      
      <div class="left">
        <div class="title">
          Analyse journalière
        </div>

        <el-date-picker
            v-model="at_picker1"
            type="date"
            format="dd/MM/yyyy"
            :clearable="false"
            value-format="dd-MM-yyyy"
            placeholder="Choississez un jour"
            :picker-options="pickerOptions"
            @change="sync()"
        >
        </el-date-picker>

        <el-date-picker
            v-model="at_picker2"
            type="date"
            format="dd/MM/yyyy"
            value-format="dd-MM-yyyy"
            placeholder="Choississez un jour"
            @change="sync()"
            :picker-options="pickerOptions"
        >
        </el-date-picker>

<!--        <div class="e small" @click="at_picker1 = $dayjs(at_picker1, 'DD-MM-YYYY').subtract(1, 'day').format('DD-MM-YYYY'); sync()" style="min-width: 34px">-->
<!--          <svg style="transform: rotate(-90deg)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M23.677 18.52c.914 1.523-.183 3.472-1.967 3.472h-19.414c-1.784 0-2.881-1.949-1.967-3.472l9.709-16.18c.891-1.483 3.041-1.48 3.93 0l9.709 16.18z"/></svg>-->
<!--        </div>-->
<!--        <div class="e small" @click="at_picker1 = $dayjs(at_picker1, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY'); sync()" style="margin-right: 4px; min-width: 34px">-->
<!--          <svg style="transform: rotate(90deg)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M23.677 18.52c.914 1.523-.183 3.472-1.967 3.472h-19.414c-1.784 0-2.881-1.949-1.967-3.472l9.709-16.18c.891-1.483 3.041-1.48 3.93 0l9.709 16.18z"/></svg>-->
<!--        </div>-->
        
        <div class="e" @click="compar_mem = 'week'; sync()" :data-active="compar_mem === 'week'">Une semaine</div>
        <div class="e" @click="compar_mem = 'year'; sync()" :data-active="compar_mem === 'year'">Un an</div>
        
      </div>
      
      <div class="right">
        <div class="e">Entrées</div>
      </div>
    </div>
    
    <div class="width">



      <div class="global">
        <!--      <div class="graph"></div>-->
        <div class="recap_total shadow" v-if="load">
          
          <div class="elem">
            <div class="title title_color total">{{ $dayjs(at, 'DD-MM-YYYY').format('dddd DD MMMM YYYY') }}</div>

            <transition name="slide-left">
              <div class="value" v-if="dataEnter" :class="{red: Math.sign(dataEnter[dataEnter.length - 1] ? dataEnter[dataEnter.length - 1].total : 0) === -1}">{{ dataEnter[dataEnter.length - 1] ? dataEnter[dataEnter.length - 1].total : 0 | formatNumber }}</div>
            </transition>
          </div>
          
          <div class="compar">
            <template v-if="dataEnter_n.length > 0">
              
              <div class="percent green" :class="{ red: Math.sign(variation_val) === -1 }">
                {{ variation_val | formatNumber }}%
                
                <svg v-if="(Math.sign(variation_val) !== -1 && variation_val !== 0)" class="green ico_trans" :title="variation_val | formatNumber" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 24 24"><path d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" /></svg>
                <svg v-else-if="variation_val !== 0" class="red ico_trans" style="transform: rotate(180deg)" :title="variation_val | formatNumber" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 24 24"><path d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" /></svg>
                <svg v-else class="ico_trans" style="transform: rotate(90deg)" :title="variation_val | formatNumber" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 24 24"><path d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" /></svg>
  
              </div>
              
              <div class="value">{{ dataEnter[dataEnter.length - 1].total - dataEnter_n[dataEnter_n.length - 1].total | formatNumber }} entrées</div>
            </template>
            
          </div>
          
          <div class="elem">
            <div class="title title_color total">{{ $dayjs(at_n, 'DD-MM-YYYY').format('dddd DD MMMM YYYY') }}</div>

            <transition name="slide-left">
              <div class="value" v-if="graphEnter !== 0" :class="{red: Math.sign(dataEnter_n[dataEnter_n.length - 1] ? dataEnter_n[dataEnter_n.length - 1].total : 0) == -1}">{{ dataEnter_n[dataEnter_n.length - 1] ? dataEnter_n[dataEnter_n.length - 1].total : 0 | formatNumber }}</div>
            </transition>
          </div>
          
<!--          <div class="elem">-->
<!--            <div class="title title_color rae">Roulettes électroniques</div>-->
<!--            <transition name="slide-left">-->
<!--              <div class="value" v-if="graphEnter !== 0" :class="{red: Math.sign(total.rae) == -1}">{{ total.rae | formatNumberdecimal }}€</div>-->
<!--            </transition>-->
<!--          </div>-->
        </div>
      </div>
      
      
      

      <div class="col_duo">

        <div class="graph_grp">
          <div class="graph shadowSmall">
            <div class="title_color entrees" :class="type">Evolution des Entrées </div>
            <v-chart :options="graphEnter" autoresize v-if="load" />
          </div>
          <div class="graph shadowSmall">
            <div class="title_color entrees" :class="type">Total des Entrées </div>
            <v-chart :options="graphEnterTotal" autoresize v-if="load" />
          </div>
        </div>

        <div class="tableau">
          <div class="ligne head" style="grid-template-columns: 20% 20% 20% 20% 20%">
            <div class="e borderRight">Date</div>
            <div class="e">Total</div>
            <div class="e avant_yellow">évolution</div>
            <div class="e">évolution N-1</div>
            <div class="e avant_red">Evolution Diff</div>
          </div>
          <template v-if="load">
            <div class="ligne min anim" style="grid-template-columns: 20% 20% 20% 20% 20%" v-for="(n, k) in dataEnter" :key="k">
              <div class="e borderRight" style="border-top: 0px solid #fff;" :style="{ color: n.color, borderColor: n.color }">{{ n.label }}</div>
              <div class="e" style="border-top: 0px solid #fff;"><number :value="n.total" round="formatNumber" :oldValue="dataEnter_n[k] ? dataEnter_n[k].total : 0" :variation="true"></number></div>
              <div class="e avant_yellow" :class="{ red: n.diff < 20 }" style="border-top: 0px solid #fff;">+ {{ n.diff }}</div>
              <div class="e" v-if="dataEnter_n[k]" style="border-top: 0px solid #fff;">+ {{ dataEnter_n[k].diff }}</div>
<!--              <div class="e green avant_red" v-if="dataEnter_n[k]" :class="{red: Math.sign(((n.diff - dataEnter_n[k].diff) / 100) * 100) === -1 }" style="border-top: 0px solid #fff;">{{ ((n.diff - dataEnter_n[k].diff) / 100) * 100 | formatNumber }}%</div>-->
              <div class="e green avant_red" v-if="dataEnter_n[k]" :class="{red: Math.sign(((n.diff - dataEnter_n[k].diff) / 100) * 100) === -1 }" style="border-top: 0px solid #fff;">{{ ((n.diff - dataEnter_n[k].diff) / Math.abs(dataEnter_n[k].diff)) * 100 | formatNumber }}%</div>
            </div>
          </template>
<!--          Math.sign(mas_total['NetWin']) == -1-->
<!--          <div class="ligne end" style="grid-template-columns: 21% 18% 18% 15% 18% 10%" >-->
<!--            <div class="e"></div>-->
<!--          </div>-->

        </div>
        
        
        
        
      </div>
      
    </div>
  </div>
</template>

<script>

import number from "@/components/number";
export default {
  name: "Home",
  data() {
    return {
      dataEnter:  null,
      dataEnter_n:  null,
      graphEnter:  null,
      graphEnterTotal:  null,
      graph_x: [],
      load: false,

      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: 'Aujourd\'hui',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }]
      },
      at_picker1: null,
      at_picker2: null,
      at_picker2_old: null,
      at: null,
      at_n: null,
      
      compar_mem: 'week'
    }
  },
  components: { number },
  created() {
    
    this.at = this.$route.params.at
    
    if(!this.$route.params.at_n) {
      
      if(this.compar_mem === 'week')  
        this.at_n = this.$dayjs(this.at, 'DD-MM-YYYY').subtract(7, 'days').format('DD-MM-YYYY')
      else if(this.compar_mem === 'year')
        this.at_n = this.$dayjs(this.at, 'DD-MM-YYYY').subtract(1, 'year').add(1, 'days').format('DD-MM-YYYY')
      
      this.at_picker2 = this.at_n
      this.at_picker2_old = this.at_n
    }

    this.sync()
    
    
  },
  
  methods: {
    sync() {
      // let _this = this
      
      if(this.at_picker1)
        this.at = this.at_picker1
      else if(this.at)
        this.at_picker1 = this.at
      
      if(this.at_picker2) {
        if(this.at_picker2 === this.at_picker2_old) {
          
          if(this.compar_mem === 'week')
            this.at_n = this.$dayjs(this.at, 'DD-MM-YYYY').subtract(7, 'days').format('DD-MM-YYYY')
          else if(this.compar_mem === 'year')
            this.at_n = this.$dayjs(this.at, 'DD-MM-YYYY').subtract(1, 'year').add(1, 'days').format('DD-MM-YYYY')
          
          this.at_picker2 = this.at_n
        }
        else
          this.at_n = this.at_picker2

        this.at_picker2_old = this.at_picker2
      }
      else 
        this.at_n = null
      
      // this.$nextTick(() => {
      //   _this.at_picker2 = _this.at_n
      // })

      this.graph_x = []
      
      this.$httpStatum.get('/ptech/enter/' + this.at).then((data) => {

        this.$httpStatum.get('/ptech/enter/' + this.at_n).then((data_n) => {
          this.dataEnter = data.data
          this.dataEnter_n = data_n.data


          let graph_data_enter_difference = [
            {name: 'Evolution', type: 'bar', data: [], smooth: 0, itemStyle: { color: '#ff9a9a' }, barGap: 0 },
            {name: 'Evolution N-1', type: 'bar', data: [], smooth: 0, itemStyle: { color: '#f8d0d0' } },
          ]
          let graph_data_enter_total = [
            {name: 'Total', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#ff9a9a', }, lineStyle: { width: 3 } },
            {name: 'Total N-1', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#999', }, lineStyle: { width: 0 },areaStyle: {opacity: .2},},
          ]

          let labelDifference = []
          for(let n in graph_data_enter_difference)
            labelDifference.push(graph_data_enter_difference[n].name)

          let labelTotal = []
          for(let n in graph_data_enter_total)
            labelTotal.push(graph_data_enter_total[n].name)

          for(let n in this.dataEnter) {
            this.graph_x.push(this.dataEnter[n].label)
            graph_data_enter_total[0].data.push(this.dataEnter[n].total)
            graph_data_enter_difference[0].data.push(this.dataEnter[n].diff)
          }
          
          // if(this.dataEnter_n.length)
          for(let n in this.dataEnter_n) {
              graph_data_enter_total[1].data.push(this.dataEnter_n[n].total)
              graph_data_enter_difference[1].data.push(this.dataEnter_n[n].diff)
          }


          this.gener_graph('graphEnter', graph_data_enter_difference, labelDifference, {
            trigger: 'axis', backgroundColor: '#f0f2f5', textStyle: { color: '#7b7b7f' },
            axisPointer: {
              type: 'shadow',
              shadowStyle: { color: '#999', opacity: 0.1 }
            }
          })
          this.gener_graph('graphEnterTotal', graph_data_enter_total, labelTotal, { trigger: 'axis', backgroundColor: '#f0f2f5', textStyle: { color: '#7b7b7f' }, axisPointer: { type: 'cross' } })

          this.load = true
        })
      })
    },
    gener_graph(title, data, label = [], tooltip) {
      this[title] = {
        legend: { left: '20px', data: label, textStyle: { color: '#7b7b7f', fontFamily: 'Product Sans', fontWeight: '700' },inactiveColor: '#ccc' },
        grid: { left: '20px', right: '30px', top: '50px', bottom: '24px', height: 'auto', containLabel: true },
        xAxis: { type: 'category', boundaryGap: true, data: this.graph_x, axisLine: { lineStyle: { color: '#7b7b7f' } }, show: true },
        yAxis: { type: 'value', axisLabel: { formatter: '{value}' }, axisPointer: { snap: true }, axisLine: { lineStyle: { width: 0, color: '#7b7b7f' }, },
          splitLine: { lineStyle: { color: '#e6e5eb'  } }
        },
        series: data,
        tooltip: tooltip
      }
    }
  },
  computed: {
    variation_val() {
      return ((this.dataEnter[this.dataEnter.length - 1].total - this.dataEnter_n[this.dataEnter_n.length - 1].total) / Math.abs(this.dataEnter_n[this.dataEnter_n.length - 1].total)) * 100
    }
  }
  
}
</script>

<style scoped>

  .col_duo { display: flex; align-items: flex-start; justify-content: space-between; padding: 16px 16px 32px}
  .col_duo .graph:nth-child(1) { margin-right: 16px; }
  /*.col_duo .graph:nth-child(2) { margin-left: 8px; }*/
  .col_duo .graph_grp { width: 50%; margin-right: 16px; }
  .col_duo .graph_grp .graph { background: #fff; height: 396px; width: 100%; margin-bottom: 16px; }
  .col_duo .tableau { width: 50%; background: #fff; min-height: 415px; }
  .col_duo .graph .echarts { width: 100%; height: 350px; }



  .global { height: 100px; display: flex; background: #fff; margin: 10px 16px 0 16px  }
  .global .graph { width: 70%; border-right: 1px solid #e3e6ed; }
  .global .recap_total { display: flex; flex-direction: row; align-items: stretch; width: 100%; flex-wrap: wrap; }
  .global .recap_total .compar { width: 200px; display: flex; align-items: center; justify-content: center; flex-direction: column; border-right: 1px solid #e3e6ed; flex: 0 0 auto; font-size: 24px }
  .global .recap_total .compar .percent { display: flex; align-items: center; font-size: 28px }
  .global .recap_total .compar .percent svg { margin-left: 4px; }
  .global .recap_total .compar .value { font-size: 18px; margin-top: 6px; color: #7b7b7f }
  
  .global .recap_total .elem:first-child .title { text-align: right; flex-direction: row-reverse; padding-right: 12px; }
  .global .recap_total .elem:first-child .title::before { margin-left: 8px; margin-right: 0; } 
  .global .recap_total .elem:first-child .value { justify-content: flex-end; padding-right: 14px; }
  
  
  .global .recap_total .elem { width: calc(100% / 2 - 100px); border-right: 1px solid #e3e6ed; }
  .global .recap_total .elem:last-child { border-right: 0; }
  .global .recap_total .elem .title { text-transform: uppercase; background: #fcf7f7; color: #111; font-weight: 900; height: 35px; display: flex; align-items: center; padding-left: 12px; margin: 4px; border-radius: 4px }
  .global .recap_total .elem .value { font-size: 24px; height: calc(100% - 48px); display: flex; align-items: center; padding-left: 14px; }


</style>